import { useEffect, useRef, useState } from "react";
import React from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { useTranslation } from "react-i18next";
import { useLayoutV2 } from "../../../context/LayoutProvider";
import _ from 'lodash';
import { updatePartner } from "../partner-business";
import CountryService from "../../../services/country-service";

const EditContactInfoForm = (props: any) => {
  const { setLoadingProgress, setSuccessProgress, setErrorProgress } = useLayoutV2();
  const { onHide, fetchCallBack, partner } = props;

  const formikRef = useRef(null);
  const { t } = useTranslation('language', { keyPrefix: 'partner_contact_form' });
  const { t: errorTrans } = useTranslation('language', { keyPrefix: 'errors' });
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
    fetchCountry();
  }, [props]);

  const fetchCountry = async () => {
    const countries = await getCountries();
    setCountries(countries);
  }
  const getCountries = async () => {
    try {
      const response = await CountryService.getCountries();
      if (response && response.status === 200) {
        if (response.data.data && response.data.data.length > 0) {
          return response.data.data;
        }
      }
    } catch (error) {
      console.log("getCountries: " + error);
    }
  };

  const onSubmitUpdatePartner = async () => {
    try {
        // @ts-ignore: Object is possibly 'null'.
      const { values, dirty, isValid } = formikRef?.current;
      // @ts-ignore: Object is possibly 'null'.
      formikRef.current.validateForm();
      // @ts-ignore: Object is possibly 'null'.
      if (formikRef) {
        setLoadingProgress(errorTrans('txt_loading'));
        let updateData = {
          ...partner,
          person_email: values.person_email?.trim()?.toLowerCase(),
          person_first_name: values.person_first_name ,
          person_last_name:  values.person_last_name,
          person_address:  values.person_address,
          person_additional_address: values.person_additional_address,
          person_post_code:  values.person_post_code,
          person_city: values.person_city,
          person_country: values.person_country,
        };

        let updateRes =  await updatePartner(updateData.uuid || '', updateData);
        if (updateRes) {
          setSuccessProgress(t('txt_update_partner_success'));
          fetchCallBack(updateRes);
          onHide()
          return;
        }
       
        throw new Error('txt_update_partner_failed');

      }
    } catch (e: any) {
      setErrorProgress(errorTrans(e?.message || 'txt_update_partner_failed'));
    }
  };

  const validationSchema = Yup.object().shape({
    person_email: Yup.string()
      .email(errorTrans('txt_email_format'))
      .required(errorTrans('txt_required'))
  });

  const initialFormValues = () => {
    let initialDonor = {
      person_first_name: partner ? partner.person_first_name : "",
      person_last_name: partner ? partner.person_last_name : "",
      person_email: partner ? partner.person_email : "",
      person_address: partner ? partner.person_address : "",
      person_additional_address: partner ? partner.person_additional_address : "",
      person_post_code: partner ? partner.person_post_code : "",
      person_city: partner ? partner.person_city : "",
      person_country: partner ? partner.person_country : "DE",
    };

    return initialDonor;
  };

  return (
    <>
      <div className="add-donor-form">
        <div className="add-donor-form-container">
          <Formik
            innerRef={formikRef}
            initialValues={initialFormValues()}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting }) => {
              //console.log("values >>>", values);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleBlur,
              handleSubmit,
              setFieldValue,
              handleChange,
            }) => (
              <form onSubmit={handleSubmit}>
                <div className="row-content">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="element-form mb-24">
                        <label className="label mb-8">
                          {t('txt_first_name')}
                        </label>
                        <InputText
                          type="text"
                          name="person_first_name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.person_first_name}
                          maxLength={40}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="element-form mb-24">
                        <label className="label mb-8">
                          {t('txt_last_name')}
                        </label>
                        <InputText
                          type="text"
                          name="person_last_name"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.person_last_name}
                          maxLength={40}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <div className="element-form mb-24">
                        <label className="label mb-8">
                          {t('txt_email')}
                          <span className="required-label ml-4"> *</span>
                        </label>
                        <InputText
                          className={`${touched.person_email && errors.person_email
                            ? "has-error"
                            : ""
                            }`}
                          type="text"
                          name="person_email"
                          onChange={(item) => {
                            setFieldValue(
                              "person_email",
                              item.target.value,
                              true
                            );
                          }}
                          onBlur={handleBlur}
                          value={values.person_email}
                          maxLength={255}
                        />
                        <div
                          className={`${touched.person_email && errors.person_email
                            ? "error"
                            : ""
                        }`}
                      >
                        {touched.person_email && errors.person_email ? `${errors.person_email}` : ''}
                      </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="element-form mb-24">
                        <label className="label mb-8">{t('txt_address')}</label>
                        <InputText
                          type="text"
                          name="person_address"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.person_address}
                          maxLength={100}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="element-form mb-24">
                        <label className="label mb-8">{t('txt_additional_address')}</label>
                        <InputText
                          type="text"
                          name="person_additional_address"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.person_additional_address}
                          maxLength={100}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="element-form mb-24">
                        <label className="label mb-8">{t('txt_postal_code')}</label>
                        <InputText
                          type="text"
                          name="person_post_code"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.person_post_code}
                          maxLength={10}
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <div className="element-form mb-24">
                        <label className="label mb-8">{t('txt_city')}</label>
                        <InputText
                          type="text"
                          name="person_city"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.person_city}
                          maxLength={85}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-12">
                      <div className="element-form mb-24">
                        <label className="label mb-8">{t('txt_country')}</label>
                          <Dropdown
                            className="dropdown-component-v2"
                            panelClassName="dropdown-panel-v2 dropdown-panel-bottom-v2"
                            name="payment_country"
                            value={values.person_country}
                            options={countries || []}
                            onChange={(item: any) => {
                              setFieldValue(
                                "person_country",
                                item.value,
                                false
                              );
                              handleChange(item);
                            }}
                            optionLabel="name"
                            optionValue="ISO2"
                            appendTo="self"
                            filter
                            filterBy="name"
                            filterPlaceholder={t('txt_search')}
                          />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-24">
                    <div className="col-md-12">
                      <div className="d-flex justify-content-start gap-24">
                        <Button
                          className="wi-danger-button-v2 h48 flex-1"
                          type="submit"
                          label={`${t('txt_cancel')}`}
                          onClick={() => onHide()}
                        />
                        <Button
                          className="wi-primary-button-v2 h48 flex-1"
                          type="submit"
                          label={`${t('txt_save')}`}
                          onClick={() => {
                              if(partner) {
                                onSubmitUpdatePartner()
                              }
                            }
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default EditContactInfoForm;
