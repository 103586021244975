import React, { useRef } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import moment from "moment";
import _ from "lodash";
import { Calendar } from "primereact/calendar";
import MatchFundingManagerAPI from "../../../services/matchFundingManager";
import "./create-match-funding-form.scss";
import {
  WIFormDropdownV2,
  WIFormInputNumberV2,
} from "../../../components_v2/common/form-fields";
import { useLayoutV2 } from "../../../context/LayoutProvider";
import { MATCH_FUNDING_TYPE_LIST, MATCH_FUNDING_TYPES, STATUS_CODE } from "../../../components_v2/utils/utils";

const FORM_DEFAULT_VALUE = {
  uuid: uuidv4(),
  campaign_id: "",
  rule_type: MATCH_FUNDING_TYPES.FIXED,
  status: STATUS_CODE.INACTIVE,
  start_date: moment().isValid() ? moment().toDate() : new Date(),
  end_date: moment().isValid() ? moment().toDate() : new Date(),

  budget: 0,
  limit: 0,
  fixed_value: 0,
  percentage: 0,
};

const CreateMatchFundingForm = (props: any) => {
  const {
    onHide,
    matchFunding,
    campaigns,
    fetchCallBack,
  } = props;
  const formikRef = useRef<any>(null);
  const { t } = useTranslation("language", { keyPrefix: "match_funding_manager" });
  const { t: globalTrans } = useTranslation("language");
  const { t: errorTrans } = useTranslation("language", { keyPrefix: "errors" });
  const {
    turnOffProgress,
    setLoadingProgress,
    setErrorProgress,
    setSuccessProgress
  } = useLayoutV2();

  const formatInputData = (formData: any) => {
    const isFixed = formData.rule_type === MATCH_FUNDING_TYPES.FIXED;
    return {
      ...formData,
      start_date: moment.utc(moment(formData.start_date).format("DD.MM.YYYY"), 'DD.MM.YYYY').startOf('day').add(1, 'hour').toISOString(),
      end_date: moment.utc(moment(formData.end_date).format("DD.MM.YYYY"), 'DD.MM.YYYY').endOf('day').add(1, 'hour').toISOString(),

      budget: Number(formData.budget || 0),
      fixed_value: isFixed && formData.fixed_value ? Number(formData.fixed_value) : null,
      limit: !isFixed && formData.limit ? Number(formData.limit) : null,
      percentage: !isFixed && formData.percentage ? Number(formData.percentage) : null,
    };
  }

  const validateData = (data: any) => {
    if (moment(data.end_date).isBefore(moment(data.start_date))) {
      throw new Error("txt_error_date");
    }
  }

  const onSuccess = () => {
    fetchCallBack();
    onHide();
  }

  const onSubmitAddNew = async () => {
    try {
      await formikRef.current.validateForm();
      const { values, dirty, isValid } = formikRef?.current;

      if (formikRef && dirty && isValid) {
        setLoadingProgress(errorTrans("txt_loading"));
        let data = formatInputData(values);
        validateData(data);

        if (!matchFunding) {
          let createRes = await MatchFundingManagerAPI.create(data);
          if (createRes?.status === 200 && createRes?.data) {
            setSuccessProgress(t("txt_create_success"));
            onSuccess();
          } else {
            throw new Error("txt_create_match_funding_failed");
          }
        }
      }
    } catch (e: any) {
      const message = `txt_${e?.response?.data?.error_code}` || e.message || 'txt_create_match_funding_failed';
      setErrorProgress(errorTrans(message));
    }
  };

  const onSubmitUpdate = async () => {
    try {
      await formikRef?.current?.validateForm();
      const { values, isValid } = formikRef?.current;

      if (formikRef && isValid) {
        setLoadingProgress(errorTrans("txt_loading"));
        let data = formatInputData(values);
        validateData(data);

        let updateRes = await MatchFundingManagerAPI.update(matchFunding.uuid, data);
        if (updateRes?.status === 200 && updateRes?.data) {
          setSuccessProgress(t("txt_update_success"));
          onSuccess();
        } else {
          throw new Error('txt_update_match_funding_failed');
        }
      }
    } catch (e: any) {
      const message = `txt_${e?.response?.data?.error_code}` || e.message || 'txt_create_match_funding_failed';
      setErrorProgress(errorTrans(message));
    }
  };

  const validationSchema = Yup.object().shape({
    campaign_id: Yup.string()
      .typeError(errorTrans("txt_required"))
      .required(errorTrans("txt_required"))
      .min(32, errorTrans("txt_required")),
    budget: Yup.number()
      .required(errorTrans("txt_required"))
      .min(1, errorTrans("txt_value_min")),
    rule_type: Yup.string()
      .required(errorTrans("txt_required")),
    status: Yup.string()
      .required(errorTrans("txt_required")),
    start_date: Yup.date()
      .typeError(errorTrans("txt_required"))
      .required(errorTrans("txt_required")),
    end_date: Yup.date()
      .typeError(errorTrans("txt_required"))
      .required(errorTrans("txt_required"))
      .min(Yup.ref('start_date'), errorTrans("txt_error_date")),
    fixed_value: Yup.number().notRequired().when('rule_type', (rule_type, schema) => {
      if (rule_type === MATCH_FUNDING_TYPES.FIXED)
        return schema.required(errorTrans("txt_required")).min(1, errorTrans("txt_value_min"));
      return schema;
    }).max(Yup.ref('budget'), errorTrans("txt_value_max")),
    limit: Yup.number().notRequired().when('rule_type', (rule_type, schema) => {
      if (rule_type !== MATCH_FUNDING_TYPES.FIXED)
        return schema.required(errorTrans("txt_required")).min(1, errorTrans("txt_value_min"));
      return schema;
    }).max(Yup.ref('budget'), errorTrans("txt_value_max")),
  });

  const initialFormValues = () => {
    let initialValues: any = FORM_DEFAULT_VALUE;
    if (matchFunding) {
      initialValues = _.pick(matchFunding, [
        "uuid",
        "campaign_id",
        "rule_type",
        "status",
        "start_date",
        "end_date",
        "budget",
        "limit",
        "percentage",
        "fixed_value",
      ]);
      initialValues.fixed_value = matchFunding.fixed_value ?? 0;
      initialValues.limit = matchFunding.limit ?? 0;
      initialValues.start_date = moment(matchFunding.start_date).toDate();
      initialValues.end_date = moment(moment.utc(matchFunding.end_date).add(-1, 'hour').format('DD.MM.YYYY'), 'DD.MM.YYYY').endOf('day').toDate();
    }

    return initialValues;
  };

  return (
    <div className="create-match-funding-form">
      <Formik
        innerRef={formikRef}
        initialValues={initialFormValues()}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          //console.log("values >>>", values);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleSubmit,
          handleChange,
          setFieldValue,
          handleBlur,
        }) => (
          <form onSubmit={handleSubmit}>
            <div className="row-content">
              <div className="row mb-24">
                <div className="col-md-6">
                  <div className="element-form">
                    <label className="label mb-12">
                      {t("txt_start_date")} <span className="required-label ml-4">*</span>
                    </label>
                    <div className="calendar-item">
                      <Calendar
                        id="basic"
                        name="start_date"
                        className="wi-calendar-v2"
                        value={values.start_date}
                        onBlur={handleBlur}
                        dateFormat="dd.mm.yy"
                        iconPos="right"
                        icon="fa-solid fa-calendar-days"
                        showIcon={true}
                        onChange={(item) => {
                          setFieldValue("start_date", item.value, false);
                        }}
                        appendTo="self"
                      />
                    </div>
                    <div className={`${_.get(errors, 'start_date') && _.get(touched, 'start_date') ? "error" : ""}`}>
                      {`${_.get(touched, 'start_date') && _.get(errors, 'start_date') || ''}`}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="element-form">
                    <label className="label mb-12">
                      {t("txt_end_date")} <span className="required-label ml-4">*</span>
                    </label>
                    <div className="calendar-item">
                      <Calendar
                        id="basic"
                        name="end_date"
                        className="wi-calendar-v2"
                        value={values.end_date}
                        onBlur={handleBlur}
                        dateFormat="dd.mm.yy"
                        iconPos="right"
                        icon="fa-solid fa-calendar-days"
                        showIcon={true}
                        onChange={(item) => {
                          setFieldValue("end_date", item.value, false);
                        }}
                        appendTo="self"
                      />
                    </div>
                    <div className={`${_.get(errors, 'end_date') && _.get(touched, 'end_date') ? "error" : ""}`}>
                      {`${_.get(touched, 'end_date') && _.get(errors, 'end_date') || ''}`}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row mb-24">
                <div className="col-md-6">
                  <WIFormDropdownV2
                    title={t("txt_type")}
                    name="rule_type"
                    isRequired={true}
                    options={MATCH_FUNDING_TYPE_LIST.map(c => ({ ...c, name: globalTrans(c.name) }))}
                    style={{ width: "auto" }}
                    optionLabel="name"
                    optionValue="code"
                    appendTo="self"
                  />
                </div>
                <div className="col-md-6">
                  <WIFormInputNumberV2
                    title={t("txt_budget")}
                    name="budget"
                    useGrouping={true}
                    onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                      if (e.key === '.') {
                        e.preventDefault();
                      }
                    }}
                    hideErrorWhenLoading={true}
                    isRequired={true}
                  />
                </div>
              </div>
              <div className="row mb-24">
                {
                  values.rule_type === MATCH_FUNDING_TYPES.FIXED
                    ? <div className="col-md-6">
                      <WIFormInputNumberV2
                        title={t("txt_fixed_value")}
                        name="fixed_value"
                        useGrouping={true}
                        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                          if (e.key === '.') {
                            e.preventDefault();
                          }
                        }}
                        hideErrorWhenLoading={true}
                        isRequired={true}
                      />
                    </div>
                    : <div className="col-md-6">
                      <WIFormInputNumberV2
                        title={t("txt_limit")}
                        name="limit"
                        useGrouping={true}
                        onKeyDown={(e: React.KeyboardEvent<HTMLInputElement>) => {
                          if (e.key === '.') {
                            e.preventDefault();
                          }
                        }}
                        hideErrorWhenLoading={true}
                        isRequired={true}
                      />
                    </div>
                }
                <div className="col-md-6">
                  <WIFormDropdownV2
                    name="campaign_id"
                    title={t("txt_campaign")}
                    isRequired={true}
                    optionLabel="name.de"
                    optionValue="uuid"
                    appendTo="self"
                    disabled={matchFunding}
                    hideErrorWhenLoading={true}
                    options={campaigns}
                    filter
                    filterBy="name.de"
                    placeholder={t("txt_choose_campaign")}
                    filterPlaceholder={t('txt_search')}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="d-flex justify-content-between gap-24">
                    <Button
                      className="wi-danger-button-v2 h48 flex-1"
                      type="submit"
                      label={`${t("txt_cancel")}`}
                      onClick={() => onHide()}
                    />
                    <Button
                      className="wi-primary-button-v2 h48 flex-1"
                      type="submit"
                      label={`${t(matchFunding ? "txt_save" : "txt_btn_add")}`}
                      onClick={() => {
                        if (matchFunding) {
                          onSubmitUpdate();
                        } else {
                          onSubmitAddNew();
                        }
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        )}
      </Formik>
    </div>
  );
};

export default CreateMatchFundingForm;
