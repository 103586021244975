import * as React from "react";
import "./assign-campaign-form.scss";
import { Formik } from "formik";
import { useRef } from "react";
import * as Yup from "yup";
import { DonationManagerAPI } from "../../../../../../services";
import _ from "lodash";
import { Button } from "primereact/button";
import { useTranslation } from "react-i18next";
import { useLayoutV2 } from "../../../../../../context/LayoutProvider";
import {
  WIFormDropdownV2,
} from "../../../../../../components_v2/common/form-fields";

const AssignCampaignForm = (props: any) => {
  const {
    campaigns,
    onHide,
    donationID,
    donation,
    fetchDonationDetail
  } = props;
  
  const formikRef = useRef(null);
  const { t } = useTranslation('language', { keyPrefix: 'donation_detail' });
  const { t: errorTrans } = useTranslation('language', { keyPrefix: 'errors' });
  const { setLoadingProgress, setSuccessProgress, setErrorProgress } = useLayoutV2();

  const initialCampaignValues = () => {
    return {
      campaign_id: donation?.campaign_id || "",
    };
  };

  const campaignFormSchema = Yup.object().shape({
    campaign_id: Yup.string().nullable().required("This field is required."),
  });

  const onAssignCampaign = async () => {
    try {
      // @ts-ignore: Object is possibly 'null'.
      const { values } = formikRef?.current;
      // @ts-ignore: Object is possibly 'null'.
      formikRef?.current?.validateForm();
      // @ts-ignore: Object is possibly 'null'.
      if (formikRef && formikRef?.current?.dirty && formikRef?.current?.isValid) {
        setLoadingProgress(errorTrans('txt_loading'));
        
        const response = await DonationManagerAPI.assignCampaign(donationID, {...values});
        if (response && response.status === 200) {
          onHide();
          fetchDonationDetail();
          setSuccessProgress(t('txt_assign_campaign_success'));
        } else {
          throw new Error('txt_assign_campaign_failed');
        }

      }
    } catch (error: any) {
      const message = `txt_${error?.response?.data?.error_code}` || error.message || 'txt_assign_campaign_failed';
      setErrorProgress(errorTrans(message));
    }
  };

  return (
    <div className="assign-campaign-form">
     <Formik
          enableReinitialize={false}
          innerRef={formikRef}
          initialValues={initialCampaignValues()}
          validationSchema={campaignFormSchema}
          onSubmit={(values, { setSubmitting }) => { }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            setFieldValue,
          }) => (
            <form onSubmit={handleSubmit}>
              <div className="row-content">
                <div className="row">
                  <div className="col-md-12">
                    <WIFormDropdownV2 
                      name="campaign_id"
                      title={t("txt_campaign")}
                      isRequired={true}
                      optionLabel="name.de"
                      optionValue="uuid"
                      appendTo="self"
                      disabled={false}
                      hideErrorWhenLoading={true}
                      options={campaigns}
                      filter
                      filterBy="name.de"
                      placeholder={t("txt_choose_campaign")}
                      filterPlaceholder={t('txt_search')}
                      />
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="d-flex justify-content-start btn-assign-campaign gap-24">
                      <Button
                        className="wi-danger-button-v2 h48 flex-1"
                        type="submit"
                        label={t("txt_cancel")}
                        onClick={() => onHide()}
                      />
                      <Button
                        className="wi-primary-button-v2 h48 flex-1"
                        type="submit"
                        label={t("txt_save")}
                        onClick={() => onAssignCampaign()}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </form>
          )}
        </Formik>
    </div>
  );
};

export default AssignCampaignForm;
