import { AxiosClient } from "./httpClient";

export default class DonationManagerAPI {
  // static getDonations(status_id: string, data: any) {
  //   return AxiosClient.get(`donations/${status_id}/pagination`, data);
  // }

  // static getAllDonationsWithPagination(data: any) {
  //   return AxiosClient.get(`/donations/pagination`, data);
  // }

  static getDonationsBySearch(data: any) {
    return AxiosClient.post(`/donations/search`, data);
  }

  static getDonation(uuid: string) {
    return AxiosClient.get(`/donations/${uuid}`);
  }

  static bookDonationGeo(uuid: string) {
    return AxiosClient.post(`/donations/${uuid}/geo-coordinate`, {});
  }

  static getCodeById(uuid: string) {
    return AxiosClient.get(`/codes/${uuid}`);
  }

  static generateDonationPDF(uuid: any, generateType: any, lang: any, yearDonation?: any) {
    if (yearDonation) {
      return AxiosClient.get(`/donations/${uuid}/${generateType}/${yearDonation}?lang=${lang}`);
    }
    return AxiosClient.get(`/donations/${uuid}/${generateType}?lang=${lang}`);
  }

  static updateDonation(uuid: any, data: any) {
    return AxiosClient.put(`/donations/${uuid}`, data);
  }

  static updateDonor(uuid: any, data: any) {
    return AxiosClient.put(`/donations/${uuid}/donor`, data);
  }

  static exportMultipleDonations(ids: any, fields: any, include_header: boolean, is_all: boolean) {
    return AxiosClient.post(`/donations/export`, { ids, fields, include_header, is_all });
  }

  static generateRecurringReceiptPDF(uuid: any, lang: any) {
    return AxiosClient.get(`/donations/${uuid}/recurring-receipt?lang=${lang}`);
  }

  static generateRecurringCertificatePDF(uuid: any, lang: any, yearDonation: any) {
    return AxiosClient.get(`/donations/${uuid}/recurring-certificate/${yearDonation}?lang=${lang}`);
  }

  static generateReceiptsPDFByYear(uuid: any, yearDonation: any) {
    return AxiosClient.get(`/donations/${uuid}/recurring-receipt/${yearDonation}`);
  }

  static generateMultipleCertificatesPDF(ids: any, lang: any) {
    return AxiosClient.post(`/donations/batch/download-certificates`, { ids, lang });
  }

  static generateMultipleReceiptsPDF(ids: any, lang: any) {
    return AxiosClient.post(`/donations/batch/download-receipts`, { ids, lang });
  }

  static addDonationManual(data: any) {
    return AxiosClient.post(`/donations/manual`, data);
  }

  static getYearlyDonations(year: number) {
    return AxiosClient.get(`/donations/recurring-yearly/${year}`);
  }

  static sendMailYearlyDonations(year: number) {
    return AxiosClient.post(`/donations/recurring-yearly/${year}/send-mail`, {});
  }

  /**
   * Book Geo for last year big-brother donations
   * 
   * NOTE:
   * a big-brother donation is the oldest donation (based on payment date) in a group of
   * donations having the same parent donation
   */
  static bookGeoForLastYearBigBrotherDonations() {
    return AxiosClient.post(`/donations/book-geo-for-last-year-big-brother-donations`, {});
  }

  static assignCampaign(uuid: any, data: any) {
    return AxiosClient.put(`/donations/${uuid}/campaign`, data);
  }
}
