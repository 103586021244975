import * as React from "react";
import "./wi-account.scss";
import { ReactComponent as BellSvg } from "../../../assets/images/icons/notification-bell.svg";
import { ReactComponent as ProfileSvg } from "../../../assets/images/menu/user-circle.svg";
import { ReactComponent as LockOutSvg } from "../../../assets/images/menu/logout.svg";
import { ReactComponent as BuildingSvg } from "../../../assets/images/icons/blank-building.svg";
import { ReactComponent as KeySvg } from "../../../assets/images/icons/key.svg";

import { Menu } from "primereact/menu";
import { useTranslation } from "react-i18next";
import { parseJSONtoObj, resetToken } from "../../../utils/logic";
import { useNavigate } from "react-router-dom";
import useAuth from "../../../context/useAuth";
import { env } from "../../../environment";
import { WI_TENANT_ID } from "../../utils/utils";

const WiAccountUser = (props: any) => {
  const { title } = props;
  const navigate = useNavigate();
  const { auth } = useAuth();
  const menu = React.useRef<any>(null);
  const { t } = useTranslation('language', { keyPrefix: 'side_bar_menu' });

  const itemsAction = [
    {
      label: `${t('txt_profile')}`,
      className: 'item-menu',
      icon: <ProfileSvg className="icon-svg"></ProfileSvg>,
      hidden: false,
      command: () => {
        navigate(`/profile/settings`);
      }
    },
    // {
    //   label: `${t('txt_organization_profile')}`,
    //   className: 'item-menu',
    //   icon: <BuildingSvg className="icon-svg"></BuildingSvg>,
    //   hidden: auth.tenant_id === WI_TENANT_ID,
    //   command: () => {
    //     navigate(`/organization/settings`);
    //   }
    // },
    // {
    //   label: `${t('txt_change_password')}`,
    //   className: 'item-menu',
    //   icon: <KeySvg className="icon-svg"></KeySvg>,
    //   command: () => {
    //     navigate(`/change-password`);
    //   }
    // },
    {
      label: `${t('txt_logout')}`,
      className: 'item-menu special-item',
      icon: <LockOutSvg className="icon-svg"></LockOutSvg>,
      hidden: false,
      command: () => {
        resetToken();
        navigate('/login');
      }
    },

  ];

  const renderAvatar = (userDetails: any) => {
    const config = userDetails?.custom_config ? parseJSONtoObj(userDetails?.custom_config) : {};
    return (
      <div className="user ml-24" onClick={(event) => menu?.current?.toggle(event)}>
        {
          userDetails.avatar
            ? <img className="user-avatar- m-0" src={`${env.PUBLIC_UPLOAD_URL}/${userDetails?.avatar}`} alt="User Avatar" />
            : (<div className="user-no-avatar" style={{ background: config?.avartar_color }}>
              {userDetails?.first_name?.charAt(0)?.toUpperCase()}{userDetails?.last_name?.charAt(0)?.toUpperCase()}
            </div>)
        }
      </div>
    )
  }

  const renderAvatarPartner = (userDetails: any) => {
    if(userDetails?.tenant_id !== WI_TENANT_ID) { 
      return (
        <div className="partner">
          {
            userDetails?.partner?.partner_logo && <img className="partner-avatar- m-0" src={`${env.PUBLIC_UPLOAD_URL}/${userDetails?.partner?.partner_logo}`} alt="Parnter Logo" loading="lazy" />
          }
        </div>
      )
    }
    
  }

  return (
    <div className="user-account pt-16 pb-16 pl-24 pr-24">
      <div className="title">
        {title}
      </div>
      <div className="account">
        {/* <div className="notifications">
          <BellSvg></BellSvg>
          <span className="dot-noti"></span>
        </div> */}
        {renderAvatarPartner(auth)}
        {renderAvatar(auth)}
        <Menu model={itemsAction.filter(c => !c.hidden)} popup ref={menu} id="popup_menu" className="menu-popup-v2 dropdown-panel-bottom-v2" />
      </div>
    </div>
  );
}

export default WiAccountUser;