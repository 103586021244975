import * as React from "react";
import { useEffect, useRef, useState } from "react";
import Layout from "../../components_v2/common/layout/layout";
import { DataTable } from "primereact/datatable";
import { Column, ColumnAlignType } from "primereact/column";
import { CampaignManagerAPI, GoalManagerAPI, PartnerManagerAPI, RegionManagerAPI } from "../../services";
import { useSearchParams, Link } from "react-router-dom";
import MenuDot from "../../components_v2/common/menu-dot/menu-dot";
import {
  buildProgressTemplate,
  formatDateBodyTemplate,
} from "../common/column-template-table/column-template";
import _ from "lodash";
import {
  getGoalObjectSearchValue,
  getGoalObjectWithValues,
  getGoalPayloadConditions,
  getObjectWithValues
} from "./goal-manager-util";
import { Sidebar } from 'primereact/sidebar';
import AddGoalFormComponent from "./components/add-goal-form/add-goal-form";
import { GOAL_STATUS_LIST, GOAL_TYPES } from "../../utils/utils";
import moment from "moment";
import WITagWIR from "../wilderness-run-details/components/tag-wir/tag-wir";
import { env } from "../../environment";
import useAuth from "../../context/useAuth";
import { GOAL_STATUS_LIST_V2, PERMISSIONS_V2 } from "../../components_v2/utils/utils";
import { useLayoutV2 } from "../../context/LayoutProvider";
import { useTranslation } from "react-i18next";
import GoalFilterForm from "./components/goal-filter-form";
import WISearchField from "../../components_v2/common/search/wi-search-field";
import { ReactComponent as FilterSvg } from "../../assets/images/icons/filter-cog.svg";
import { ReactComponent as PlusSvg } from "../../assets/images/icons/add-plus.svg";
import { Button } from "primereact/button";
import WIPaginator from "../../components_v2/common/wi-paginator/wi-paginator";
import WiStatus from "../../components_v2/common/wi-status/wi-status";
import { formatDateTimeV2, formatDateV2, formatDecimalV2 } from "../../utils/logic";

export type FilterParameters = {
  pageNo: number;
  range: number;
  search?: string;
  where: {
    or: any[],
    region_id: string[],
    partner_id: string[],
    status: string[],
    campaign_id: string[]
  };
  order: [];
};

const GoalManager = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const pageNumber = Math.max(+(searchParams?.get("pageNumber") || 1) - 1, 0);
  const noRows = Math.min(
    Math.max(+(searchParams?.get("noRows") || 50), 50),
    1000
  );
  const { getLanguage } = useLayoutV2();
  const sortField = searchParams?.get("sortField") || "created_at";
  const sortOrder = +(searchParams?.get("sortOrder") || -1);
  const searchField = searchParams?.get("search") || "";
  const filterCampaign = searchParams?.get('campaign_id') ? searchParams?.get('campaign_id')?.split(',') || [] : [];
  const filterPartner = searchParams?.get('partner_id') ? searchParams?.get('partner_id')?.split(',') || [] : [];
  const filterRegions = searchParams?.get('region_id') ? searchParams?.get('region_id')?.split(',') || [] : [];
  const filterStatus = (searchParams?.get('filter_status') || GOAL_STATUS_LIST.map((c) => c.code).join(',')).split(',');
  const [isShowSidebarFilter, setIsShowSidebarFilter] = useState(false);
  const [search, setSearch] = useState<any>(null);
  const { t } = useTranslation("language", { keyPrefix: "goals_manager" });
  const { auth } = useAuth();
  const [first, setFirst] = useState(pageNumber * noRows);
  const [rows, setRows] = useState(noRows);
  const statusTypeAll = 'all';
  const toast = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [goals, setGoals] = useState({
    data: [],
    totalPage: 0,
  });
  const [sortConfig, setSortConfig] = useState<any>({
    sortField: sortField,
    sortOrder: sortOrder,
  });
  const [filterValues, setFilterValues] = useState<FilterParameters>({
    pageNo: pageNumber,
    range: noRows,
    search: searchField,
    where: {
      or: searchField ? getGoalObjectSearchValue(searchField) : [],
      status: filterStatus,
      region_id: filterRegions,
      partner_id: filterPartner,
      campaign_id: filterCampaign
    },
    order: [],
  });

  const [page, setPage] = useState(pageNumber);
  const [goal, setGoal] = useState<any>(null);
  const [isShowSidebar, setIsShowSidebar] = useState(false);
  const [campaigns, setCampaigns] = useState<any>([]);
  const [regions, setRegions] = useState<any>([]);
  const [partners, setPartners] = useState<any[]>([]);
  const [statusGoal, setStatusGoal] = useState<any[]>([]);
  const [selectedStatusGoal, setSelectedStatusGoal] = useState<any>(filterStatus.length !== 1 ? statusTypeAll : filterStatus[0]);
  const onBasicPageChange = (event: any) => {
    setPage(event.page);
    setFirst(event.first);
    setRows(event.rows);
  };

  const permissions = {
    canUpdateGoal: auth?.permissions?.includes(PERMISSIONS_V2.GOAL_UPDATE),
    canViewPartner: auth?.permissions?.includes(PERMISSIONS_V2.PARTNER_VIEW),
    canViewCode: auth?.permissions?.includes(PERMISSIONS_V2.CODE_VIEW),
    canViewRegion: auth?.permissions?.includes(PERMISSIONS_V2.REGION_VIEW),
    canViewCampaign: auth?.permissions?.includes(PERMISSIONS_V2.CAMPAIGN_VIEW),
    canCreateGoal: auth?.permissions?.includes(PERMISSIONS_V2.GOAL_CREATE),
  };

  const setupGoalsStatus = async () => {
    const statusList = _.cloneDeep(GOAL_STATUS_LIST);
    statusList.unshift({ code: 'all', name: { de: 'All', en: 'All' } });
    setStatusGoal(statusList);
  };

  const fetchGoalData = async () => {
    setIsLoading(true);
    const payload = getGoalPayloadConditions(filterValues, sortConfig);
    try {
      const resGoals = await GoalManagerAPI.getGoals({
        pageNo: page,
        range: rows,
        ...payload,
      });

      if (resGoals && resGoals.status === 200) {
        setGoals({
          data: resGoals.data.records,
          totalPage: resGoals.data.total || 0,
        });
      }
      setIsLoading(false);
    } catch (error) {
      setGoals({
        data: [],
        totalPage: 0,
      });
      setIsLoading(false);
    }
  };


  const fetchAreasData = async () => {
    setIsLoading(true);
    const [regionsRes, campaignsRes, partnersRes] = await Promise.all([
      RegionManagerAPI.getAllRegions(),
      CampaignManagerAPI.getAllCampaigns(),
      PartnerManagerAPI.getAllPartners()
    ]);

    // @ts-ignore: Object is possibly 'null'.
    if (regionsRes && regionsRes.status === 200 && regionsRes.data.records?.length > 0) {
      setRegions(_.sortBy(regionsRes.data.records, (item) => item.name?.de?.toLowerCase()));
    }
    // @ts-ignore: Object is possibly 'null'.
    if (campaignsRes && campaignsRes.status === 200 && campaignsRes.data.records?.length > 0) {
      setCampaigns(_.sortBy(campaignsRes.data.records, (item) => item.name?.de?.toLowerCase()));
    }

    if (partnersRes.status === 200 && partnersRes.data.records.length > 0) {
      setPartners(_.sortBy(partnersRes.data.records, (item) => item.name?.de?.toLowerCase()));
    }

    setIsLoading(false);
  };


  useEffect(() => {
    fetchAreasData();
    setupGoalsStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (typeof page === "number") {
      setFilterValues({
        ...filterValues,
        pageNo: page,
        range: rows,
      });
      setSearchParams({
        pageNumber: (page + 1).toString(),
        noRows: rows.toString(),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rows]);

  useEffect(() => {
    const searchParams = {
      pageNumber: (page + 1).toString(),
      noRows: rows.toString(),
      search: filterValues.search,
      sortField: sortConfig.sortField,
      sortOrder: sortConfig.sortOrder,
      filterStatus: filterValues.where.status.join(',') || '',
      filterRegions: filterValues.where.region_id?.join(',') || '',
      filterPartner: filterValues.where.partner_id?.join(',') || '',
      filterCampaign: filterValues.where.campaign_id?.join(',') || ''
    };

    setSearchParams(_.omitBy(searchParams, (p) => !p));
    fetchGoalData(); // temporary
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterValues, sortConfig]);

  const onSearchFunc = (searchText: string) => {
    const where_conditions = getGoalObjectWithValues(filterValues.where);
    const or_conditions = getGoalObjectSearchValue(searchText);
    where_conditions["or"] = searchText ? or_conditions : null;
    setFilterValues({
      pageNo: filterValues.pageNo,
      range: filterValues.range,
      where: where_conditions,
      search: searchText,
      order: filterValues.order,
    });
    if (page !== 0) {
      setPage(0);
      setFirst(0);
    }
  };

  const onFilterChange = (values: any, fields: any) => {
    const { field, value } = values;
    const where_conditions = getObjectWithValues(filterValues.where);
    fields.forEach((field: any) => {
      const data = values?.get(field);
      where_conditions[field] = data;
    });
    if (field !== "dates") {
      where_conditions[field] = value;
    } else {
      const dates = value.map((c: any) => moment(c).format("DD/MM/YYYY"));
      where_conditions[field] = dates;
      where_conditions.from_run_date = dates[0];
      where_conditions.to_run_date = dates[1];
    }
    setFilterValues({
      ...filterValues,
      pageNo: filterValues.pageNo,
      range: filterValues.range,
      where: where_conditions,
      order: filterValues.order
    });
  };

  const onFilterRemove = async () => {
    let p = _.cloneDeep({ ...filterValues });
    p.where = Object.create({});
    if (selectedStatusGoal === statusTypeAll) {
      p.where["status"] = [...GOAL_STATUS_LIST.map((c) => c.code)];
    } else {
      p.where["status"] = [selectedStatusGoal];
    }
    p.search = '';
    setFilterValues(p);
  };

  const onHideSideBar = () => {
    setIsShowSidebar(false);
    if (goal) {
      setGoal(null);
    }
  }

  const tableColumns = [
    {
      field: "name",
      header: t("txt_name"),
      width: "200px",
      align: "left",
      bodyTemPlate: (rowData: any) => {
        // eslint-disable-next-line react/no-danger-with-children
        return <span dangerouslySetInnerHTML={{ __html: rowData.name?.replace('\n', '<br/>') || '-' }}></span>
      },
      disabledSort: false,
    },
    {
      field: "region_id",
      header: t("txt_region"),
      width: "180px",
      align: "left",
      bodyTemPlate: (rowData: any, column: any) => {
        return permissions.canViewRegion && rowData.region_id ? <Link onClick={(e) => e.stopPropagation()} className="table-ref-link-cell" to={`/regions/${rowData.region_id}`}>{rowData.region_name}</Link> : <span>{rowData?.region_name || '-'}</span>;
      },
      disabledSort: true,
    },
    {
      field: "campaign_id",
      header: t("txt_campaign"),
      width: "160px",
      align: "left",
      bodyTemPlate: (rowData: any, column: any) => {
        return permissions.canViewCampaign && rowData.campaign_id ? <Link onClick={(e) => e.stopPropagation()} className="table-ref-link-cell" to={`/campaigns/${rowData.campaign_id}`}>{rowData.campaign_name}</Link> : <span>{rowData?.campaign_name || '-'}</span>;
      },
      disabledSort: false,
    },
    {
      field: "code",
      header: t("txt_code"),
      width: "120px",
      bodyTemPlate: (rowData: any, column: any) => {
        if (permissions.canViewCode && rowData?.code) {
          let urlCodeParam = null;
          if (rowData?.goal_type === GOAL_TYPES.REGION && rowData?.region_identifier) {
            urlCodeParam = `?region=${rowData.region_identifier}`;
          } else if (rowData?.goal_type === GOAL_TYPES.CAMPAIGN && rowData?.campaign_code) {
            urlCodeParam = `?campaign=${rowData.campaign_code}`
          }
          if (urlCodeParam) {
            return <a target="_blank" href={`${env.DONATION_URL}${urlCodeParam}&goal=${rowData?.code}`} className="table-ref-link-cell" rel="noreferrer">{rowData?.code}</a>;
          }
        }
        return <span>{rowData?.code || ''}</span>;
      },
      align: "left",
      disabledSort: false,
    },
    {
      field: "partner_id",
      header: t("txt_partner"),
      width: "calc(92% - 1574px)",
      minWidth: "230px",
      bodyTemPlate: (rowData: any, column: any) => {
        return permissions.canViewPartner && rowData.partner_id ? <Link onClick={(e) => e.stopPropagation()} className="table-ref-link-cell" to={`/partners/${rowData.partner_id}`}>{rowData.partner_name}</Link> : <span>{rowData?.partner_name || '-'}</span>;
      },
      align: "left",
      disabledSort: false,
    },
    {
      field: "status",
      header: t("txt_status"),
      width: "130px",
      bodyTemPlate: (rowData: any) => {
        const statusItem = GOAL_STATUS_LIST_V2.find((item: any) => item.code === rowData.status);
        return <WiStatus item={statusItem}></WiStatus>
      },
      align: "center",
      disabledSort: true,
    },
    {
      field: "donation_goal",
      header: t("txt_goal_m2"),
      width: "135px",
      bodyTemPlate: (rowData: any) => { return formatDecimalV2(rowData.donation_goal, getLanguage()) },
      align: "center",
      disabledSort: false,
    },
    {
      field: "",
      header: t("txt_process"),
      width: "280px",
      bodyTemPlate: buildProgressTemplate("", "donation_goal", "total_area"),
      align: "left",
      disabledSort: true,
    },
    {
      field: "total_area",
      header: t("txt_protect_m2"),
      width: "140px",
      bodyTemPlate: (rowData: any) => {
        return formatDecimalV2(rowData.total_area, getLanguage())
      },
      align: "left",
      disabledSort: false,
    },
    {
      field: "",
      header: `${t('txt_date')}`,
      width: "160px",
      bodyTemPlate: (rowData: any) => {
        return <div>{formatDateTimeV2(rowData.created_at, getLanguage())}</div>;
      },
      align: "center",
      disabledSort: false,
    },
  ];

  const dynamicColumns = tableColumns.map((col, i) => {
    return (
      <Column
        key={`${col.field}_${i}`}
        field={col.field}
        header={col.header}
        style={{
          width: col.width,
          minWidth: col.minWidth,
        }}
        body={col.bodyTemPlate}
        align={col.align as ColumnAlignType}
        sortable={!col?.disabledSort}
      />
    );
  });

  const buildMenu = (rowData: any) => {
    const defaultActions = [
      {
        label: `${t('txt_edit')}`,
        icon: "wi-edit",
        command: (e: any) => {
          setGoal(rowData);
          setIsShowSidebar(true);
        },
        hidden: !permissions.canUpdateGoal,
        disabled: rowData.status !== "active"
      },
    ];
    return <MenuDot items={[...defaultActions]}></MenuDot>;
  };

  return (
    <Layout title={t("txt_title")}>
      <div className="header">
        <div className="header-content pt-16 pb-16 pl-24 pr-24">
          <div className="search-container">
            <WISearchField
              icon={"pi pi-search"}
              placeholder={t("txt_search")}
              setSearch={(value: any) => setSearch(value.global.value)}
              enterSearch={(value: any) => onSearchFunc(search)}
            />
            <div className="filter-btn">
              <div className="filter-btn-content p-8 ml-16"
                onClick={() => setIsShowSidebarFilter(true)}>
                <FilterSvg ></FilterSvg>
              </div>
            </div>
          </div>
          <Button
            className="wi-primary-button-v2"
            label={t("txt_btn_add")}
            hidden={!permissions.canCreateGoal}
            onClick={(e: any) => {
              setGoal(null);
              setIsShowSidebar(true);
            }}
          >
            <PlusSvg className="icon-svg" />
          </Button>
        </div>
      </div>
      <div className="p-table-v2 pt-12 pl-24 pr-24">
        <DataTable
          loading={isLoading}
          value={goals.data}
          scrollable
          dataKey="id"
          scrollHeight="calc(100vh - 214px)"
          scrollDirection="both"
          lazy
          onSort={(e: any) => {
            setSortConfig({
              sortField: e.sortField,
              sortOrder: e.sortOrder,
            });
          }}
          sortField={sortConfig.sortField}
          sortOrder={sortConfig.sortOrder}
        >
          {dynamicColumns}
          <Column
            frozen
            alignFrozen="right"
            style={{ width: "64px" }}
            body={buildMenu}
          ></Column>
        </DataTable>
        <WIPaginator
          first={first}
          rows={rows}
          totalRecords={goals.totalPage}
          onPageChange={onBasicPageChange}
        />
      </div>
      <Sidebar
        visible={isShowSidebarFilter}
        position="right"
        className="wi-sidebar-v2 p-sidebar-md sidebar-right"
        style={{ width: '400px' }}
        onHide={() => setIsShowSidebarFilter(false)}
      >
        <div className="sidebar-content">
          <div className="headline pt-24 pb-24">
            <h6>{t('txt_filter')}</h6>
          </div>
          <GoalFilterForm
            fetchCallBack={() => setIsShowSidebarFilter(false)}
            filterValues={filterValues}
            onRemove={onFilterRemove}
            setSearch={setSearch}
            onSearchFunc={onSearchFunc}
            onHide={() => setIsShowSidebarFilter(false)}
            fields={['region_id', "campaign_id", "partner_id", "status"]}
            campaigns={campaigns}
            partners={partners}
            regions={regions}
            onSubmit={(values: any, fields: any) => onFilterChange(values, fields)}
          />
        </div>
      </Sidebar>
      <Sidebar
        visible={isShowSidebar}
        position="right"
        className="wi-sidebar-v2 p-sidebar-md sidebar-right"
        style={{ width: '30em' }}
        onHide={() => onHideSideBar()}>
        <div className="sidebar-content">
          <div className="headline pt-24 pb-24">
            <h6>{goal ? 'Update goal' : 'Add a new goal'}</h6>
          </div>
          <AddGoalFormComponent
            regions={regions}
            campaigns={campaigns}
            fetchCallBack={(() => fetchGoalData())}
            toast={toast}
            onHide={() => onHideSideBar()}
            goalData={goal}
            notify={toast}
            partners={partners}
          />
        </div>
      </Sidebar>
    </Layout >
  );
};

export default GoalManager;
